.yui-layout-bd {
	overflow: hidden !important;
}

.errorMessage {
	font-family: "OpenSans_Light";
	font-size: 15px;
	color: #9B1E34;
	font-weight: 800;
	text-shadow: 1px 1px #ccc;
	margin-top: 10px;
	margin-bottom: 10px;
	padding: 0%;
}

.pageTitleID {
	width: 100%;
	background-color: #1C426D;
	border: 0px solid #ffffff; /*
    background-image: url(back_test2.jpg);
    background-repeat: no-repeat;
    */
}

.pageTitleID1 {
	width: 100%;
	background-color: #E6ECF3;
	border: 0px solid #ffffff; /*
    background-image: url(back_test2.jpg);
    background-repeat: no-repeat;
    */
}
/*.clientLogo img {
    width: 45%;
}*/
.active-dir-notice {
	font-size: 12px;
	color: gray;
	font-family: Arial;
	text-align: center;
}

.login-mode-notice {
	font-size: 12px;
	color: gray;
	font-family: Arial;
	text-align: center;
}
