@import url('https://fonts.googleapis.com/css?family=Lato:100,300,400,700,900&display=swap');

body {
	background-color: #fff;
	font-family: 'Lato', sans-serif;
	font-size: 16px;
}

.btn-blue-icon, .btn-blue, .btn-grey {
	color: #ffffff;
	padding: 11px 36px;
	margin-right: 10px;
	-webkit-border-radius: 6px;
	-moz-border-radius: 6px;
	border-radius: 6px;
	border: none;
	margin-top: 10px;
	margin-bottom: 12px;
	font-family: 'Lato', sans-serif;
	cursor: pointer;
	background: #008ecd;
	border: 1px solid #008ecd;
}

button:focus {
	outline: none;
}

button {
	font: 16px 'Lato', sans-serif;
}

.btn-grey {
	background-color: #ededed;
	border: 1px solid #008ecd;
	color: #008ecd;
	margin-right: 0;
}

.row-form {
	overflow: hidden;
	margin-bottom: 8px;
	line-height: 1;
	display: -webkit-flex;
	display: -moz-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	-moz-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-justify-content: center;
	justify-content: center;
	-moz-justify-content: center;
}

.mf-1, .mf-2, .mf-3, .mf-4, .mf-5, .mf-6, .mf-7, .mf-8, .mf-9, .mf-10, .mf-11, .mf-12, .mf-firstHalf, .mf-secondHalf, .mf-thirdHalf {
	width: 16.6666%;
	display: inline-block;
}

.mf-1 {
	width: 8.3333%;
}

.mf-2 {
	width: 16.6666%;
}

.mf-3 {
	width: 24.9999%;
}

.mf-4 {
	width: 33.3332%;
}

.mf-5 {
	width: 41.6665%;
}

.mf-6 {
	width: 49.9998%;
}

.mf-7 {
	width: 58.3331%;
}

.mf-8 {
	width: 66.6664%;
}

.mf-9 {
	width: 74.9997%;
}

.mf-10 {
	width: 83.333%;
}

.mf-11 {
	width: 91.6663%;
}

.mf-12 {
	width: 99.9996%;
}

.mf-firstHalf {
	width: 62%;
}

.mf-secondHalf, .mf-thirdHalf {
	width: 35%;
	float: right;
}

.mainTable {
	max-width: 1000px;
	margin: 0 auto;
}

.mainTableOne {
	max-width: 1180px;
	margin: 0 auto;
}

.fullWidthTable {
	max-width: 100%;
	margin: 0 auto;
}

.row-form label {
	white-space: nowrap;
	text-align: right;
	font-weight: normal;
	color: #00568f;
	font-size: 16px;
	padding: 6px 0px 1px 0px;
	float: left;
	width: 30%;
	line-height: 1.2;
	margin: 0px 0px 0px 1%;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

.wSpace .row-form label {
	white-space: normal;
}

.row-form .mf-firstHalf label, .row-form .mf-secondHalf label {
	width: 50%;
}

.row-form .mf-thirdHalf label {
	width: 33%;
}

.mainTable .row-form select,
.mainTable .row-form input[type="text"],
.mainTable .row-form input[type="date"],
.mainTable .row-form input[type="password"],
.mainTable .row-form textarea, .mainTable
.row-form input[type="number"],
.mainTable .row-form input[type="tel"],
.multiSelectContainer {
	font-family: 'Lato', sans-serif;
	font-size: 14px;
	float: right;
	color: #414141;
	height: 34px;
	padding: 7px 10px 7px 10px;
	width: 66%;
	outline: none;
	border: 1px solid #c6c6c6;
	-webkit-border-radius: 6px;
	-moz-border-radius: 6px;
	border-radius: 6px;
	-webkit-transition: 0.3s ease-in-out all;
	-moz-transition: 0.3s ease-in-out all;
	-ms-transition: 0.3s ease-in-out all;
	-o-transition: 0.3s ease-in-out all;
	transition: 0.3s ease-in-out all;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

.multiSelectContainer {
	padding: 1px 10px 0px 5px;
	height: auto;
	position: relative;
}

	.multiSelectContainer span {
		display: inline-block;
		margin: 3px 0px 3px 4px;
		padding: 3px 5px 3px 5px;
		float: left;
		-webkit-border-radius: 4px;
		-moz-border-radius: 4px;
		border-radius: 4px;
		color: #00568f;
	}

	.multiSelectContainer img {
		position: absolute;
		top: 0;
		right: 0;
		cursor: pointer;
		border-radius: 0 5px 5px 0;
	}

.mainTable .row-form textarea {
	height: 100px;
	resize: none;
}

.row-btn {
	text-align: center;
}

.row-form .field_full-12 label {
	width: 9.5%;
}

.row-form .field_full-12 textarea, .row-form .field_full-12 input[type="text"], .row-form .field_full-12 .textBoxValidate {
	width: 88.7%;
}

.row-form .field_full-8 label {
	width: 14.5%;
}

.row-form .field_full-7 label {
	width: 25.5%;
}

.mainTable .row-form .textBoxValidate, .mainTable .row-form .selectBoxValidate { /*..Use this class when we need to add validator line..*/
	width: 66%;
	float: right;
}

.row-form .field_full-8 textarea, .row-form .field_full-8 input[type="text"], .row-form .field_full-8 .selectBoxValidate, .row-form .field_full-8 .textBoxValidate, .row-form .field_full-8 select, .row-form .field_full-8 input[type="number"] {
	width: 83%;
}

.mainTable .row-form .textBoxTwoField {
	width: 66%;
	float: right;
}

	.mainTable .row-form .textBoxTwoField .iconContainer, .mainTable .row-form .textBoxTwoField input[type="text"], .mainTable .row-form .textBoxTwoField label, .mainTable .row-form .textBoxTwoField select, .mainTable .row-form .textBoxTwoField .twoColValidate {
		width: 48%;
		float: left;
	}

		.mainTable .row-form .textBoxTwoField .iconContainer input[type="text"], .mainTable .row-form .textBoxTwoField .iconContainer .multiSelectContainer {
			width: 100%;
			padding-right: 40px;
		}

		.mainTable .row-form .textBoxTwoField .twoColValidate input[type="text"], .mainTable .row-form .textBoxTwoField .twoColValidate select {
			width: 100%;
		}

	.mainTable .row-form .textBoxTwoField label {
		margin-left: 0;
	}

		.mainTable .row-form .textBoxTwoField .iconContainer:nth-child(2), .mainTable .row-form .textBoxTwoField input[type="text"]:nth-child(2), .mainTable .row-form .textBoxTwoField label:nth-child(2), .mainTable .row-form .textBoxTwoField select:nth-child(2), .mainTable .row-form .textBoxTwoField .twoColValidate:nth-child(2) {
			float: right;
		}

.row-form .field_full-7 textarea, .row-form .field_full-7 input[type="text"], .row-form .field_full-7 .selectBoxValidate, .row-form .field_full-7 .textBoxTwoField, .row-form .field_full-7 .textBoxValidate, .row-form .field_full-7 select, .row-form .field_full-7 input[type="number"], .row-form .field_full-7 input[type="date"] {
	width: 71%;
}

.mainTable .row-form .field_full-7 .textBoxValidate .multiSelectContainer, .mainTable .row-form .selectBoxValidate select, .mainTable .row-form .textBoxValidate input[type="text"], .mainTable .row-form .mainTable input[type="password"], .mainTable .row-form .textBoxValidate input[type="date"], .InputTable .row-form .mainTable textarea, .row-form .field_full-8 .selectBoxValidate input[type="text"] {
	width: 100%;
}

.mainTable .row-form .textBoxTwoDigit input[type="text"]:first-child {
	width: 50%;
	float: left;
}

.mainTable .row-form .textBoxTwoDigit label {
	width: 22%;
	float: left;
	margin-left: 1%;
	margin-right: 2%;
}

.mainTable .row-form .textBoxTwoDigit input[type="text"]:last-child {
	width: 24%;
	padding: 7px;
}

.mf-icon .textBoxValidate {
	position: relative;
}

	.mf-icon .textBoxValidate input[type="text"] {
		padding-right: 48px;
	}

	.mf-icon .textBoxValidate img {
		position: absolute;
		right: 5px;
		top: 7px;
		cursor: pointer;
	}

.mt-15 {
	margin-top: 15px;
}

.mt-25 {
	margin-top: 25px;
}

.col-left {
	float: left;
}

.lblChkBox input[type=checkbox] + label {
	display: block;
	margin: 0;
	cursor: pointer;
	padding: 0;
	width: 100%;
}

.lblChkBox input[type=checkbox] {
	display: none;
}

	.lblChkBox input[type=checkbox] + label:before {
		content: "";
		border: 0.1em solid #c6c6c6;
		border-radius: 0.2em;
		display: inline-block;
		width: 20px;
		height: 20px;
		padding-left: 0;
		padding-bottom: 0;
		margin-right: 1px;
		vertical-align: bottom;
		color: transparent;
		transition: .2s;
		margin-top: 0;
		text-align: center;
	}

	.lblChkBox input[type=checkbox] + label:active:before {
		transform: scale(0);
	}

	.lblChkBox input[type=checkbox]:checked + label:before {
		background-color: #fff;
		border-color: #c6c6c6;
		color: #20bbef;
		background-image: url("../images/tick-chkbox.png");
		background-repeat: no-repeat;
		background-position: center center;
	}

	.lblChkBox input[type=checkbox]:disabled + label:before {
		transform: scale(1);
		border-color: #aaa;
	}

	.lblChkBox input[type=checkbox]:checked:disabled + label:before {
		transform: scale(1.2);
		background-color: #bfb;
		border-color: #bfb;
	}

.lblChkBox {
	display: inline-block;
	margin-right: 8px;
}

	.lblChkBox label span {
		top: 2px;
		position: relative;
	}

.mainTable .row-form .textBoxValidate .lblChkBox {
	padding-top: 6px;
}

.top-header .primary-menu {
	background-color: #004c91;
	background-image: url("../images/header-bg.png");
	background-repeat: repeat-x;
	background-position: left top;
	padding: 0;
}

	.top-header .primary-menu li {
		min-height: 59px;
		border-right: 1px solid #6c95b3;
		display: inline-block;
		float: left;
	}

		.top-header .primary-menu li.nav-no-border {
			border: none;
		}

		.top-header .primary-menu li:last-child {
			border-right: none;
		}

		.top-header .primary-menu li .nav-link {
			padding: 17px 30px 16px;
			font-weight: bold;
			color: white;
		}

.d-flex {
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
}

.top-header .primary-menu .navbar-nav {
	display: block;
	width: 100%;
}

.top-header .primary-menu li.nav-upload-docs .nav-link {
	padding: 5px 30px 10px;
}

.top-header .primary-menu .navbar-nav .nav-link:hover {
	text-decoration: underline;
	color: white;
}

.top-header .primary-menu .navbar-nav .nav-link.nav-pager-link:hover {
	text-decoration: none;
}

.pagerPrevIcon {
	margin: 0 20px 0 11px;
}

.pagerNextIcon {
	margin: 0 11px 0 20px;
}

.nav-pager-link input {
	width: 52px;
	border: none;
	height: 36px;
	border-radius: 4px;
	padding: 0 8px;
	margin: 0 10px;
	color: #1695b2;
}

.top-header .primary-menu li .nav-link.nav-pager-link {
	padding: 18px 30px 14px;
}

.col-pdf-view {
	background-color: #7a7a7a;
}

	.col-pdf-view .pdfContainer {
		border: 6px solid #424242;
		border-radius: 8px;
		padding: 0;
		background-color: white;
	}

		.col-pdf-view .pdfContainer .file-drop-area {
			margin: 86px 40px;
		}

.col-pdf-view {
	padding: 10px 20px;
}

	.col-pdf-view img {
		width: 100%;
	}

.col-invoice-view {
	padding: 10px 20px;
	background-color: #d9d9d9;
}

.box-container {
	border-style: solid;
	border-width: 2px;
	border-color: rgb(0, 86, 143);
	border-radius: 4px;
	background-color: rgb(255, 255, 255);
	box-shadow: 2px 3.464px 8px 0px rgba(20, 133, 255, 0.5);
	padding: 30px 20px 30px;
	border-top: 11px solid #00568f;
	margin-bottom: 14px;
}

	.box-container h2 {
		margin-left: 15px;
		margin-bottom: 17px;
		color: #00568f;
		font-size: 20px;
		font-weight: 700;
	}

.searchFld {
	position: relative;
}

	.searchFld img {
		position: absolute;
		top: 6px;
		right: 8px;
		cursor: pointer;
	}

.mainTable .row-form .searchFld input {
	padding-right: 40px;
}

.blueBoxContainer {
	border-style: solid;
	border-width: 1px;
	border-color: rgb(202, 202, 202);
	border-radius: 3px;
	background-color: rgb(255, 255, 255);
	padding-bottom: 10px;
	margin-bottom: 20px;
	box-shadow: 2px 3.464px 8px 0px rgba(0, 0, 0, 0.15);
}

	.blueBoxContainer .row-header {
		background-color: #e9f3fe;
		margin-bottom: 16px;
	}

.row-form.row-header label {
	text-align: left;
	font-size: 18px;
	padding-bottom: 10px;
}

.blueBoxContainer .textBoxTwoField {
	padding-right: 20px;
}

.iconContainer {
	position: relative;
}

	.iconContainer .textAlignmentIcon, .iconContainer .dmyIcon, .iconContainer .onOffIcon {
		position: absolute;
		right: 1px;
		top: 1px;
		bottom: 1px;
	}

	.iconContainer .iconSecondLast {
		right: 32px;
	}

.mainTable .row-form .textBoxTwoField .iconContainer.twoIconsContainer input[type="text"] {
	padding-right: 70px;
}

.iconContainer .textAlignmentIcon > img, .iconContainer .dmyIcon > img, .iconContainer .onOffIcon > img {
	border-radius: 0 5px 5px 0;
	cursor: pointer;
	position: absolute;
	right: 0;
}

.iconContainer .textAlignmentIcon div, .iconContainer .dmyIcon div, .iconContainer .onOffIcon div {
	display: none;
	padding: 4px;
	position: absolute;
	width: 124px;
	top: 34px;
	left: -123px;
	text-align: right;
	background: #fff;
	border: 1px solid #ccc;
	border-radius: 0 0 4px 4px;
	z-index: 100;
}

.iconContainer .textAlignmentIcon:hover div, .iconContainer .dmyIcon:hover div, .iconContainer .onOffIcon:hover div {
	display: block;
}

.row-ov-form {
	overflow: visible;
}

.iconContainer .textAlignmentIcon div img, .iconContainer .dmyIcon div img, .iconContainer .onOffIcon div img {
	cursor: pointer;
	border: 1px solid transparent;
}

	.iconContainer .textAlignmentIcon div img:hover, .iconContainer .dmyIcon div img:hover, .iconContainer .onOffIcon div img:hover {
		border: 1px solid #00568f;
		border-radius: 8px;
	}

.file-drop-area {
	position: relative;
	display: -webkit-box;
	display: flex;
	-webkit-box-align: center;
	align-items: center;
	max-width: 100%;
	padding: 145px 25px;
	border-radius: 3px;
	-webkit-transition: 0.2s;
	transition: 0.2s;
	border: 1px dashed #E5E9EC;
	flex-direction: column;
}

	.file-drop-area.is-active {
		background-color: rgba(255, 255, 255, 0.05);
	}

	.file-drop-area .fake-btn {
		flex-shrink: 0;
		background-color: rgba(255, 255, 255, 0.04);
		border: 1px solid rgba(255, 255, 255, 0.1);
		border-radius: 3px;
		padding: 8px 15px;
		margin-right: 10px;
		font-size: 12px;
		text-transform: uppercase;
	}

	.file-drop-area input {
		background: #008ecd;
		box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1);
		border-radius: 5px;
		border: none;
		color: white;
		font-family: Lato;
		font-style: normal;
		font-weight: 400;
		font-size: 15px;
		line-height: 14px;
		letter-spacing: 0.02em;
		padding: 10px 20px;
		margin-top: 50px;
	}

	.file-drop-area .file-msg {
		overflow: hidden;
		text-overflow: ellipsis;
		font-family: "Lato";
		font-style: normal;
		font-weight: normal;
		margin-left: 0;
		display: block;
		font-size: 30px;
		margin-top: 20px;
		color: #00578f;
	}

	.file-drop-area .file-input {
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		cursor: pointer;
		opacity: 0;
		margin-top: 0;
	}

		.file-drop-area .file-input:focus {
			outline: none;
		}

.col-pdf-view .file-drop-area img {
	width: auto;
}

.file-drop-area {
	background: white;
	border: 4px solid #00578f;
	border-radius: 8px;
}

.file-drop-container, .file-drop-browse {
	text-align: center;
	width: 100%;
	float: left;
	clear: both;
}
